import { gql } from "@apollo/client"

const INSERT_ATTENDEE = gql`
  mutation insertAttendee($attendee: AttendeeInsertInput!) {
      insertedAttendee: insertAttendee(attendee: $attendee) {
      _id
      firstName
      lastName
      email
    }
  }
`

export { INSERT_ATTENDEE }
