import { gql } from "@apollo/client"

const FORM = gql`
  query form($_id: String!) {
    form(_id: $_id) {
      _id
      fields {
        label
        type
        required
        options
      }
    }
  }
`

export { FORM }
