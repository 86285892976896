import React from "react"
import {useProjectForm, useSaveRegistration} from "./hooks"
import {Button, Col, Empty, Form, Modal, Result, Row, Skeleton} from "antd"
import {getFieldComponent} from "./utils"
import {EditOutlined} from "@ant-design/icons"
import {isNil, omit, omitBy} from "lodash";

const isBrowser = typeof window !== "undefined"
export default function FormPage() {

  const params = new URLSearchParams(isBrowser ? window?.location?.search : "")
  const {form, loading} = useProjectForm(params.get("_id"))
  const {insertAttendee, loading: saveLoading} = useSaveRegistration()
  const [registerForm] = Form.useForm()

  function register() {
    registerForm.validateFields().then(values => {
      const attendee = {
        type: "attendee",
        project: params.get("_id"),
        firstName: values["Prénom"].trim(),
        lastName: values["Nom"].trim(),
        email: values["E-mail"].trim(),
        infos: Object.keys(omit(omitBy(values, isNil), ["info"])).map(key => ({
          title: key,
          value: values[key].toString().trim(),
          type: typeof values[key],
        })),
      }
      insertAttendee(attendee)
        .then(() => {
          registerForm.resetFields()
          Modal.success({
            okButtonProps: {
              size: "large",
            },
            width: "auto",
            icon: undefined,
            content: (
              <Result
                status="success"
                title={
                  "Félicitations " +
                  attendee.lastName.toUpperCase() +
                  " " +
                  attendee.firstName +
                  " !"
                }
                subTitle={
                  "Nous vous remercions pour votre inscription.\n" +
                  " Un mail de confirmation est envoyé à votre adresse " +
                  attendee.email
                }
              />
            ),
            footer: null,
            centered: true,
          })
        })
        .catch(e => {
          let message = "Erreur dans l'inscription"
          if (e.toString().toLowerCase().includes("duplicate")) {
            message = "Cette addresse mail est déjà utilisée!"
          }
          Modal.error({
            width: "auto",
            content: <Result
              status="error"
              title={message}
            />,
            icon: undefined,
            centered: true,
            okButtonProps: {
              size: "large"
            },
          })
          console.log(e)
        })
    })
  }

  return (
    <Row style={{padding: 10}} justify="center">
      <Col xs={24} sm={23}>
        {loading && <Skeleton active/>}
        {form && (
          <>
            <Form
              labelCol={{xs: 24, sm: 24, md: 3, lg: 4}}
              wrapperCol={{xs: 24, sm: 24, md: 18, lg: 18}}
              form={registerForm}
            >
              {form?.fields?.map((field, i) => (
                <Form.Item
                  key={i}
                  label={field.type === "info" ? " " : field.label}
                  name={field.label}
                  colon={field.type === "info" ? false : true}
                  rules={
                    [
                      {required: field.required && field.type !== "info"},
                      ...(field.type === "email" ?
                          [
                            {
                              type: "email",
                              message: "Veuillez saisir un e-mail valide!",
                            }
                          ]
                          :
                          []
                      )
                    ]
                  }
                  //initialValue={field.type === "select" ? field.options[0] : undefined}
                >
                  {getFieldComponent(field)}
                </Form.Item>
              ))}
              <Form.Item label={" "} colon={false}>
                <Button
                  icon={<EditOutlined/>}
                  onClick={register}
                  type="primary"
                  block
                  loading={saveLoading}
                >
                  Inscription
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
        {!loading && !form && (
          <Empty description="Aucun formulaire disponible"/>
        )}
      </Col>
    </Row>
  )
}
