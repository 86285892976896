import {useMutation, useQuery} from "@apollo/client"
import {FORM} from "../../apollo/queries"
import {INSERT_ATTENDEE} from "../../apollo/mutations"

export function useProjectForm(_id) {
  const {data, loading} = useQuery(FORM, {
    variables: {_id},
    skip: !_id
  })
  return {
    form: data?.form,
    loading,
  }
}

export function useSaveRegistration() {
  const [insertOne, {loading}] = useMutation(INSERT_ATTENDEE)

  function insertAttendee(attendee) {
    return insertOne({
      variables: { attendee},
    })
  }

  return {
    loading,
    insertAttendee,
  }
}
