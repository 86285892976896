import {
  Alert,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select, Space,
  TimePicker,
} from "antd"
import React from "react"

export function getFieldComponent(field) {
  switch (field.type) {
    case "date":
      return <DatePicker format="DD/MM/YYYY"/>
    case "select":
      return (
        <Select options={field.options.map(value => ({label: value, value}))}/>
      )
    case "checkbox":
      return (
        <Checkbox.Group style={{width: '100%'}}>
          <Space direction="vertical" size={10}>
            {field.options.map((option, index) =>
              (<Checkbox value={option} key={index}>{option}</Checkbox>))}
          </Space>
        </Checkbox.Group>
      );
    case "time":
      return <TimePicker/>
    case "radio":
      return (
        <Radio.Group>
          <Space direction="vertical" size={10}>
            {field.options.map((value, i) => (
              <Radio key={i} value={value}>
                {value}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )
    case "number":
      return <InputNumber/>
    case "email":
      return <Input autoComplete="off" type="email"/>
    case"info":
      return <Alert message={<div dangerouslySetInnerHTML={{__html: field.label.replaceAll("\n", "<br/>")}}/>}
                    type="info"/>
    default:
      return <Input autoComplete="off"/>
  }
}
